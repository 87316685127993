import React, { useState, useEffect } from 'react';
import { HeartOutlined, EyeOutlined, ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import '../../styles/BestSeller.scss';
import instance from '../../helpers/axiosconfig';

const BestsellerShowcase = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/products');
      const expandedProducts = response.data.flatMap(product => 
        product.variations.map(variation => ({
          _id: product._id,
          category: product.category,
          baseDescription: product.baseDescription,
          variations: [variation]
        }))
      );
      setProducts(expandedProducts);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleQuickView = (productId, variationId) => {
    const id = productId.$oid || productId;
    navigate(`/product/${id}`, {
      state: { 
        selectedVariationId: variationId.$oid || variationId
      }
    });
  };

  const renderStockBadge = (stock) => {
    if (stock > 10) {
      return (
        <div className="bestseller__badge bestseller__badge--in-stock">
          <CheckCircleFilled className="bestseller__badge-icon" />
        </div>
      );
    } else if (stock > 0) {
      return (
        <div className="bestseller__badge bestseller__badge--low-stock">
          <ExclamationCircleFilled className="bestseller__badge-icon" />
          <span>Only {stock} left</span>
        </div>
      );
    }
    return (
      <div className="bestseller__badge bestseller__badge--out-stock">
        <ExclamationCircleFilled className="bestseller__badge-icon" />
        <span>Out of Stock</span>
      </div>
    );
  };

  const formatPrice = (price) => {
    return Math.round(price).toLocaleString('en-IN');
  };

  if (loading) return <div className="bestseller__loading">Loading products...</div>;
  if (error) return <div className="bestseller__error">{error}</div>;

  return (
    <div className="bestseller-showcase" style={{ width: '100%', overflow: 'hidden' }}>
      <div className="bestseller__scroll-container" style={{
        display: 'flex',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        gap: '16px',
        padding: '16px'
      }}>
        {products.map((product) => {
          const variation = product.variations[0];
          // Calculate rounded prices
          const originalPrice = Math.round(variation.price);
          const discountedPrice = Math.round(variation.price * (1 - variation.discount / 100));
          
          return (
            <div key={variation._id.$oid} className="bestseller__card" style={{
              flex: '0 0 auto',
              width: '280px',
              scrollSnapAlign: 'start',
              marginRight: '16px'
            }}>
              <div className="bestseller__header">
                {renderStockBadge(variation.stock)}
                {variation.discount > 0 && (
                  <div className="bestseller__discount">
                    <span>{variation.discount}% OFF</span>
                  </div>
                )}
              </div>

              <div 
                className="bestseller__image-container" 
                onClick={() => handleQuickView(product._id, variation._id)}
                style={{ cursor: 'pointer' }}
              >
                <img 
                  src={variation.imageUrls[0]}
                  alt={variation.name}
                  loading="lazy"
                />
                <button className="bestseller__wishlist">
                  <HeartOutlined />
                </button>
              </div>

              <div className="bestseller__content">
                <h3 
                  className="bestseller__name" 
                  onClick={() => handleQuickView(product._id, variation._id)}
                  style={{ cursor: 'pointer' }}
                >
                  {variation.name}
                </h3>
                
                <div className="bestseller__details">
                  <div className="bestseller__volume">
                    <span className="bestseller__label" style={{ fontFamily: "'Libre Bodoni', serif" }}>
                      Size
                    </span>
                    <span className="bestseller__value">{variation.volume}</span>
                  </div>
                </div>

                <div className="bestseller__price-block">
                  <div className="bestseller__price-info">
                    <div className="bestseller__current-price">
                      <span className="bestseller__currency">₹</span>
                      <span className="bestseller__amount">
                        {variation.discount > 0 ? formatPrice(discountedPrice) : formatPrice(originalPrice)}
                      </span>
                    </div>
                    {variation.discount > 0 && (
                      <div className="bestseller__original-price">
                        <span 
                          style={{ fontFamily: "'Libre Bodoni', serif" }}
                          className="bestseller__mrp"
                        >
                          MRP ₹{formatPrice(originalPrice)}
                        </span>
                        <div 
                          className="bestseller__tax-info"
                          style={{ fontFamily: "'Libre Bodoni', serif" }}
                        >
                          (incl. of all taxes)
                        </div>
                      </div>
                    )}
                  </div>
                  {variation.stock > 0 && (
                    <div className="bestseller__shipping">
                      <span style={{ fontFamily: "'Libre Bodoni', serif" }}>
                        Free Delivery
                      </span>
                    </div>
                  )}
                </div>

                <div className="bestseller__actions">
                  <button 
                    className="bestseller__button bestseller__button--primary"
                    onClick={() => handleQuickView(product._id, variation._id)}
                  >
                    <EyeOutlined />
                    <span style={{ fontFamily: "'Libre Bodoni', serif" }}>
                      Quick View
                    </span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <style>
        {`
          .bestseller__scroll-container::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  );
};

export default BestsellerShowcase;
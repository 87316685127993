import React, { useState, useEffect } from 'react';
import '../../styles/AddressForm.scss';
import instance from '../../helpers/axiosconfig';

const INDIAN_STATES = [
  // States (28)
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  
  // Union Territories (8)
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Delhi',
  'Jammu and Kashmir',
  'Ladakh',
  'Lakshadweep',
  'Puducherry'
];

const ADDRESS_TYPES = [
  { value: 'Home', label: 'Home' },
  { value: 'Office', label: 'Office' },
  { value: 'Other', label: 'Other' }
];

const INITIAL_FORM_STATE = {
  type: 'Home',
  fullName: '',
  phone: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  pincode: '',
  isDefault: false
};

const VALIDATION_RULES = {
  fullName: (value) => !value.trim() && 'Name is required',
  phone: (value) => {
    if (!value) return 'Phone is required';
    if (!/^[1-9]\d{9}$/.test(value)) return 'Enter valid 10-digit phone number';
    return '';
  },
  email: (value) => value && !/^\S+@\S+\.\S+$/.test(value) && 'Enter valid email address',
  address1: (value) => !value.trim() && 'Address is required',
  city: (value) => !value.trim() && 'City is required',
  state: (value) => !value && 'State is required',
  pincode: (value) => {
    if (!value) return 'Pincode is required';
    if (!/^\d{6}$/.test(value)) return 'Enter valid 6-digit pincode';
    return '';
  }
};

const FormField = ({ label, error, children, required, className = '' }) => (
  <div className={`form-group ${className}`}>
    <label>
      {label} {required && '*'}
    </label>
    {children}
    {error && <span className="error-message">{error}</span>}
  </div>
);

const AddressForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [showSavedAddresses, setShowSavedAddresses] = useState(true);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/user/addresses');
      setAddresses(response.data.addresses);
      setShowSavedAddresses(response.data.addresses.length > 0);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setErrors(prev => ({
        ...prev,
        fetch: 'Failed to load addresses. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.entries(VALIDATION_RULES).forEach(([field, validate]) => {
      const error = validate(formData[field]);
      if (error) newErrors[field] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = ({ target: { name, value, type, checked } }) => {
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
    setErrors(prev => ({ ...prev, [name]: '', submit: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setSubmitLoading(true);
        const response = await instance.post('/user/addresses', formData);
        onSubmit(response.data.address);
        onClose();
      } catch (error) {
        console.error('Error saving address:', error);
        setErrors(prev => ({
          ...prev,
          submit: error.response?.data?.message || 'Failed to save address. Please try again.'
        }));
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const handleDelete = async (addressId) => {
    if (!window.confirm('Are you sure you want to delete this address?')) return;
    
    try {
      await instance.delete(`/user/addresses/${addressId}`);
      await fetchAddresses();
    } catch (error) {
      console.error('Error deleting address:', error);
      alert('Failed to delete address. Please try again.');
    }
  };

  const handleSetDefault = async (addressId) => {
    try {
      await instance.put(`/user/addresses/${addressId}`, { isDefault: true });
      await fetchAddresses();
    } catch (error) {
      console.error('Error setting default address:', error);
      alert('Failed to set default address. Please try again.');
    }
  };

  const handleEdit = (address) => {
    setFormData({
      ...address,
      address1: address.address1 || '',
      address2: address.address2 || ''
    });
    setShowSavedAddresses(false);
  };

  const selectAddress = (address) => {
    onSubmit(address);
    onClose();
  };

  const inputProps = (name, type = 'text', extras = {}) => ({
    id: name,
    name,
    value: formData[name],
    onChange: handleChange,
    className: errors[name] ? 'error' : '',
    type,
    ...extras
  });

  if (loading) {
    return (
      <div className="address-form-overlay">
        <div className="address-form">
          <div className="loading">Loading addresses...</div>
        </div>
      </div>
    );
  }

  if (showSavedAddresses && addresses.length > 0) {
    return (
      <div className="address-form-overlay" onClick={e => e.target === e.currentTarget && onClose()}>
        <div className="address-form">
          <div className="form-header">
            <h2>Select Address</h2>
            <button type="button" className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>

          {errors.fetch && (
            <div className="error-banner">{errors.fetch}</div>
          )}
          
          <div className="saved-addresses">
            {addresses.map(address => (
              <div key={address._id} className="saved-address">
                <div className="address-type">{address.type}</div>
                <div className="address-details" onClick={() => selectAddress(address)}>
                  <p className="name">{address.fullName}</p>
                  <p>{address.address1}</p>
                  {address.address2 && <p>{address.address2}</p>}
                  <p>{address.city}, {address.state} - {address.pincode}</p>
                  <p>{address.phone}</p>
                </div>
                <div className="address-actions">
                  <button 
                    className="edit-btn"
                    onClick={() => handleEdit(address)}
                  >
                    Edit
                  </button>
                  {!address.isDefault && (
                    <button 
                      className="default-btn"
                      onClick={() => handleSetDefault(address._id)}
                    >
                      Set as Default
                    </button>
                  )}
                  <button 
                    className="delete-btn"
                    onClick={() => handleDelete(address._id)}
                  >
                    Delete
                  </button>
                </div>
                {address.isDefault && <div className="default-badge">Default</div>}
              </div>
            ))}
          </div>
          
          <div className="form-actions">
            <button 
              type="button" 
              className="submit-btn" 
              onClick={() => setShowSavedAddresses(false)}
            >
              Add New Address
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="address-form-overlay" onClick={e => e.target === e.currentTarget && onClose()}>
      <div className="address-form">
        <div className="form-header">
          <h2>{formData._id ? 'Edit Address' : 'Add New Address'}</h2>
          <button type="button" className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} noValidate>
          <div className="form-grid">
            <FormField label="Address Type">
              <select {...inputProps('type')}>
                {ADDRESS_TYPES.map(({ value, label }) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </FormField>

            <FormField label="Full Name" error={errors.fullName} required>
              <input {...inputProps('fullName')} />
            </FormField>

            <FormField label="Phone Number" error={errors.phone} required>
              <input {...inputProps('phone', 'tel', { maxLength: 10 })} />
            </FormField>

            <FormField label="Email" error={errors.email}>
              <input {...inputProps('email', 'email')} />
            </FormField>

            <FormField label="Address Line 1" error={errors.address1} required className="form-full">
              <input {...inputProps('address1', 'text', { 
                placeholder: 'House No, Building Name'
              })} />
            </FormField>

            <FormField label="Address Line 2" className="form-full">
              <input {...inputProps('address2', 'text', {
                placeholder: 'Road Name, Area (Optional)'
              })} />
            </FormField>

            <FormField label="City" error={errors.city} required>
              <input {...inputProps('city')} />
            </FormField>

            <FormField label="State" error={errors.state} required>
              <select {...inputProps('state')}>
                <option value="">Select State</option>
                {INDIAN_STATES.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </FormField>

            <FormField label="Pincode" error={errors.pincode} required>
              <input {...inputProps('pincode', 'text', { maxLength: 6 })} />
            </FormField>

            <div className="form-group form-full checkbox-group">
              <label className="checkbox-label">
                <input {...inputProps('isDefault', 'checkbox')} />
                <span>Make this my default address</span>
              </label>
            </div>

            {errors.submit && (
              <div className="form-group form-full">
                <span className="error-message">{errors.submit}</span>
              </div>
            )}

            <div className="form-actions">
              {addresses.length > 0 && (
                <button 
                  type="button" 
                  className="cancel-btn" 
                  onClick={() => setShowSavedAddresses(true)}
                >
                  Use Saved Address
                </button>
              )}
              <button type="button" className="cancel-btn" onClick={onClose}>
                Cancel
              </button>
              <button 
                type="submit" 
                className="submit-btn"
                disabled={submitLoading}
              >
                {submitLoading ? 'Saving...' : 'Save Address'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressForm;
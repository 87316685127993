import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import instance from '../../helpers/axiosconfig';

const ReviewModal = ({ isOpen, onClose, productId, productName, onReviewSubmitted }) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setRating(0);
      setComment('');
      setError('');
    }
  }, [isOpen]);

  const validateForm = () => {
    if (!rating) {
      setError('Please select a rating');
      return false;
    }
    if (!comment.trim()) {
      setError('Please write a review comment');
      return false;
    }
    if (comment.length > 500) {
      setError('Review comment must not exceed 500 characters');
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log('Review Modal Props:', { productId, productName });
  }, [productId, productName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    if (!productId) {
      setError('Product ID is missing');
      return;
    }

    setIsSubmitting(true);
    setError('');

    console.log('Submitting review:', {
      productId,
      rating,
      comment: comment.trim()
    });

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Please log in to submit a review');
        return;
      }

      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const response = await instance.post('reviews', {
        productId,
        rating,
        comment: comment.trim()
      });

      if (response.data.success) {
        onReviewSubmitted();
        onClose();
      } else {
        setError(response.data.message || 'Failed to submit review');
      }
    } catch (err) {
      console.error('Review submission error:', {
        error: err,
        response: err.response?.data,
        message: err.message
      });
      setError(
        err.response?.data?.message || 
        err.message || 
        'Failed to submit review'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="review-modal-overlay">
      <div className="review-modal">
        <div className="review-modal-header">
          <h2>Add Review</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="review-modal-content">
          <p className="product-name">{productName}</p>
          
          <form onSubmit={handleSubmit}>
            <div className="rating-container">
              <p>Rate this product</p>
              <div className="stars">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star
                    key={star}
                    size={32}
                    className={`star ${star <= (hoveredRating || rating) ? 'active' : ''}`}
                    onMouseEnter={() => setHoveredRating(star)}
                    onMouseLeave={() => setHoveredRating(0)}
                    onClick={() => setRating(star)}
                  />
                ))}
              </div>
              <span className="rating-text">
                {rating ? `${rating} star${rating !== 1 ? 's' : ''}` : 'Select rating'}
              </span>
            </div>

            <div className="comment-container">
              <label htmlFor="review-comment">Your Review</label>
              <textarea
                id="review-comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Share your experience with this product..."
                required
              />
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="button-container">
              <button
                type="button"
                className="cancel-button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={!rating || !comment || isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Review'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
import React, { useState, useEffect } from 'react';
import { Star, ChevronLeft, ChevronRight, User, X } from 'lucide-react';
import axiosInstance from '../../helpers/axiosconfig';
import '../../styles/ReviewSlider.scss';

const ReviewSlider = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axiosInstance.get(`products/${productId}/reviews`);
        
        if (response.data.success) {
          setReviews(response.data.reviews);
        } else {
          throw new Error(response.data.message || 'Failed to load reviews');
        }
      } catch (err) {
        setError(`Failed to load reviews: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  const nextSlide = () => {
    setCurrentIndex((prev) => prev === reviews.length - 1 ? 0 : prev + 1);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => prev === 0 ? reviews.length - 1 : prev - 1);
  };

  const StarRating = ({ rating }) => (
    <div className="review-slider__rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          size={16}
          className={star <= rating ? 'star-filled' : 'star-empty'}
        />
      ))}
    </div>
  );

  const ReviewCard = ({ review }) => (
    <div className="review-slider__review">
      <div className="review-slider__user">
        <div className="review-slider__avatar">
          <User />
        </div>
        <span className="review-slider__username">
          {review.userId?.username || 'Anonymous'}
        </span>
      </div>

      <StarRating rating={review.rating} />
      
      <div className="review-slider__verified">
        Verified Purchase
      </div>

      <p className="review-slider__comment">
        {review.comment}
      </p>

      {review.image && (
        <div className="review-slider__image">
          <img
            src={review.image}
            alt="Review"
          />
        </div>
      )}

      <div className="review-slider__date">
        {new Date(review.createdAt).toLocaleDateString()}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="review-slider__loading">
        <div className="spinner" />
      </div>
    );
  }

  if (error) {
    return <div className="review-slider__error">{error}</div>;
  }

  if (reviews.length === 0) {
    return <div className="review-slider__empty">No reviews yet</div>;
  }

  // Get visible reviews (1 for mobile, 3 for desktop)
  const visibleReviews = isMobile
    ? [reviews[currentIndex]]
    : [
        reviews[currentIndex],
        reviews[(currentIndex + 1) % reviews.length],
        reviews[(currentIndex + 2) % reviews.length],
      ];

  return (
    <>
      <div className="review-slider">
        <div className="review-slider__container">
          <div className="review-slider__content">
            <button
              onClick={prevSlide}
              className="review-slider__nav review-slider__nav--prev"
              aria-label="Previous review"
            >
              <ChevronLeft />
            </button>

            <div className={`review-slider__reviews-wrapper ${isMobile ? '' : 'review-slider__reviews-wrapper--desktop'}`}>
              {visibleReviews.map((review, index) => (
                <ReviewCard key={index} review={review} />
              ))}
            </div>

            <button
              onClick={nextSlide}
              className="review-slider__nav review-slider__nav--next"
              aria-label="Next review"
            >
              <ChevronRight />
            </button>
          </div>

          <div className="review-slider__dots">
            {reviews.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`review-slider__dot ${
                  index === currentIndex ? 'review-slider__dot--active' : ''
                }`}
                aria-label={`Go to review ${index + 1}`}
              />
            ))}
          </div>
        </div>

        <button 
          className="review-slider__view-all"
          onClick={() => setShowAllReviews(true)}
        >
          View All Reviews
        </button>
      </div>

      {showAllReviews && (
        <div className="review-slider__modal">
          <div className="review-slider__modal-content">
            <button 
              className="review-slider__modal-close"
              onClick={() => setShowAllReviews(false)}
            >
              <X />
            </button>
            <h2>All Reviews</h2>
            <div className="review-slider__modal-reviews">
              {reviews.map((review, index) => (
                <ReviewCard key={index} review={review} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewSlider;
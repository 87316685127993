import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FaStar, FaTruck, FaBox, FaMoneyBillWave, FaCreditCard, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Footer from '../HomePageComponents/Footer';
import axiosInstance from '../../helpers/axiosconfig';
import '../../styles/ProductDetails.scss';
import Navbar from '../HomePageComponents/Navbar';
import BestSellers from '../HomePageComponents/BestSeller';
import CautionNotice from '../HomePageComponents/CautionNotice';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';
import { useSwipeable } from 'react-swipeable';
import Bought from './ProductEngagement'
// import toast from 'react-hot-toast';
import toast, { Toaster } from 'react-hot-toast';
import ReviewSlider from './ReviewSlider';





const ServiceBenefits = () => {
  return (
    <div className="service-benefits">
      <div className="benefit-item">
        <div className="benefit-icon">
          <FaBox />
        </div>
        <div className="benefit-text">
          <span className="benefit-title">Free Delivery</span>
          <span className="benefit-subtitle">On all products</span>
        </div>
      </div>
      
      <div className="benefit-item">
        <div className="benefit-icon">
          <FaMoneyBillWave />
        </div>
        <div className="benefit-text">
          <span className="benefit-title">Cash On Delivery</span>
          <span className="benefit-subtitle">Available Cash On Delivery</span>
        </div>
      </div>
      
      <div className="benefit-item">
        <div className="benefit-icon">
          <FaCreditCard />
        </div>
        <div className="benefit-text">
          <span className="benefit-title">5% Save With</span>
          <span className="benefit-subtitle">Prepaid Order</span>
        </div>
      </div>
    </div>
  );
};

const StarRating = ({ rating, totalReviews }) => {
  return (
    <div className="star-rating-container">
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <FaStar 
            key={star} 
            className={`star ${star <= rating ? 'filled' : ''}`} 
          />
        ))}
        <span className="rating-value">({rating.toFixed(1)})</span>
      </div>
      <span className="total-reviews">{totalReviews} reviews</span>
    </div>
  );
};

// const DeliveryEstimation = ({ product }) => {
//   const [pincode, setPincode] = useState('');
//   const [deliveryInfo, setDeliveryInfo] = useState(null);
//   const [isChecking, setIsChecking] = useState(false);
//   const [error, setError] = useState(null);

//   const validatePincode = (pincode) => {
//     // Check if pincode is 6 digits
//     return /^\d{6}$/.test(pincode);
//   };

//   const checkDelivery = async () => {
//     if (!validatePincode(pincode)) {
//       setError('Please enter a valid 6-digit pincode');
//       return;
//     }

//     setIsChecking(true);
//     setError(null);
//     setDeliveryInfo(null);
    
//     try {
//       // First check if pincode is serviceable
//       const pincodeResponse = await axiosInstance.get(`shipping/check-pincode/${pincode}`);
      
//       if (!pincodeResponse.data?.success) {
//         setDeliveryInfo({
//           available: false,
//           message: "Sorry, delivery is not available in your area."
//         });
//         return;
//       }

//       // Set default dimensions and weight if not provided
//       const defaultDimensions = {
//         length: 10,
//         breadth: 10,
//         height: 10,
//         weight: 0.5
//       };

//       // Ensure product price is a positive number
//       const parcelValue = product?.price;
      
//       if (!parcelValue || parcelValue <= 0) {
//         throw new Error('Invalid product price');
//       }

//       // Get shipping estimate
//       const estimateResponse = await axiosInstance.post('shipping/estimate', {
//         length: product?.dimensions?.length || defaultDimensions.length,
//         breadth: product?.dimensions?.breadth || defaultDimensions.breadth,
//         height: product?.dimensions?.height || defaultDimensions.height,
//         weight: product?.weight || defaultDimensions.weight,
//         destination_pincode: pincode,
//         origin_pincode: process.env.REACT_APP_WAREHOUSE_PINCODE || '361006',
//         destination_country_code: 'IN',
//         origin_country_code: 'IN',
//         shipment_mode: 'E',
//         shipment_type: 'P',
//         shipment_value: parcelValue
//       });

//       if (!estimateResponse.data?.success || !estimateResponse.data?.estimated_delivery_days) {
//         throw new Error(estimateResponse.data?.error || 'Failed to get delivery estimate');
//       }

//       const deliveryDate = new Date();
//       deliveryDate.setDate(deliveryDate.getDate() + 
//         Math.ceil(estimateResponse.data.estimated_delivery_days));

//       setDeliveryInfo({
//         available: true,
//         estimatedDays: estimateResponse.data.estimated_delivery_days,
//         expectedDate: deliveryDate.toLocaleDateString('en-IN', {
//           day: 'numeric',
//           month: 'long',
//           year: 'numeric'
//         }),
//         shippingCost: estimateResponse.data.total_charge || 0
//       });
//     } catch (err) {
//       console.error('Delivery check error:', err);
//       setError(err.message === 'Invalid product price' 
//         ? 'Unable to calculate shipping cost due to invalid product price.' 
//         : 'Unable to check delivery availability. Please try again.');
//       setDeliveryInfo(null);
//     } finally {
//       setIsChecking(false);
//     }
//   };

//   return (
//     <div className="delivery-estimation">
//       <div className="delivery-header">
//         <div className="icon-container">
//           <FaTruck className="truck-icon" />
//         </div>
//         <div className="header-text">
//           <h3>Delivery Options</h3>
//           <p>Check delivery availability in your area</p>
//         </div>
//       </div>

//       <div className="delivery-content">
//         <div className="pincode-input-container">
//           <input
//             type="text"
//             value={pincode}
//             onChange={(e) => setPincode(e.target.value.replace(/\D/g, '').slice(0, 6))}
//             placeholder="Enter your pincode"
//             maxLength="6"
//             className={error ? 'error' : ''}
//             aria-label="Pincode input"
//           />
//           <button
//             onClick={checkDelivery}
//             disabled={pincode.length !== 6 || isChecking}
//             className={`check-button ${pincode.length === 6 && !isChecking ? 'active' : ''}`}
//             aria-label={isChecking ? "Checking delivery" : "Check delivery"}
//           >
//             {isChecking ? (
//               <div className="loading-state">
//                 <div className="spinner"></div>
//                 <span>Checking</span>
//               </div>
//             ) : (
//               'Check'
//             )}
//           </button>
//         </div>

//         {error && (
//           <div className="error-message" role="alert">
//             {error}
//           </div>
//         )}

//         {deliveryInfo && (
//           <div 
//             className={`delivery-info ${deliveryInfo.available ? 'available' : 'unavailable'}`}
//             role="status"
//           >
//             {deliveryInfo.available ? (
//               <div className="info-content">
//                 <div className="status-indicator">
//                   <span className="status-dot"></span>
//                   <span className="status-text">Delivery Available</span>
//                 </div>
//                 <div className="delivery-details">
//                   <p className="estimation">
//                     Estimated delivery in <span>{deliveryInfo.estimatedDays} business days</span>
//                   </p>
//                   <p className="expected-date">
//                     Expected by {deliveryInfo.expectedDate}
//                   </p>
//                   {deliveryInfo.shippingCost > 0 && (
//                     <p className="shipping-cost">
//                       Shipping Cost: ₹{deliveryInfo.shippingCost.toFixed(2)}
//                     </p>
//                   )}
//                 </div>
//               </div>
//             ) : (
//               <div className="info-content">
//                 <div className="status-indicator">
//                   <span className="status-dot"></span>
//                   <span className="status-text">{deliveryInfo.message}</span>
//                 </div>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

const DeliveryEstimation = ({ product }) => {
  const [pincode, setPincode] = useState('');
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState(null);

  const validatePincode = (pincode) => {
    // Check if pincode is 6 digits
    return /^\d{6}$/.test(pincode);
  };

  const checkDelivery = () => {
    if (!validatePincode(pincode)) {
      setError('Please enter a valid 6-digit pincode');
      return;
    }

    setIsChecking(true);
    setError(null);
    setDeliveryInfo(null);
    
    // Simulate a brief loading state
    setTimeout(() => {
      try {
        // Generate random delivery days between 0 and 7
        const estimatedDays = Math.floor(Math.random() * 8);
        
        const deliveryDate = new Date();
        deliveryDate.setDate(deliveryDate.getDate() + estimatedDays);

        setDeliveryInfo({
          available: true,
          estimatedDays: estimatedDays,
          expectedDate: deliveryDate.toLocaleDateString('en-IN', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          }),
          shippingCost: 99 // Fixed shipping cost
        });
      } catch (err) {
        setError('Unable to check delivery availability. Please try again.');
        setDeliveryInfo(null);
      } finally {
        setIsChecking(false);
      }
    }, 500); // Add a small delay to show loading state
  };

  return (
    <div className="delivery-estimation">
      <div className="delivery-header">
        <div className="icon-container">
          <FaTruck className="truck-icon" />
        </div>
        <div className="header-text">
          <h3>Delivery Options</h3>
          <p>Check delivery availability in your area</p>
        </div>
      </div>

      <div className="delivery-content">
        <div className="pincode-input-container">
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value.replace(/\D/g, '').slice(0, 6))}
            placeholder="Enter your pincode"
            maxLength="6"
            className={error ? 'error' : ''}
            aria-label="Pincode input"
          />
          <button
            onClick={checkDelivery}
            disabled={pincode.length !== 6 || isChecking}
            className={`check-button ${pincode.length === 6 && !isChecking ? 'active' : ''}`}
            aria-label={isChecking ? "Checking delivery" : "Check delivery"}
          >
            {isChecking ? (
              <div className="loading-state">
                <div className="spinner"></div>
                <span>Checking</span>
              </div>
            ) : (
              'Check'
            )}
          </button>
        </div>

        {error && (
          <div className="error-message" role="alert">
            {error}
          </div>
        )}

        {deliveryInfo && (
          <div 
            className={`delivery-info ${deliveryInfo.available ? 'available' : 'unavailable'}`}
            role="status"
          >
            {deliveryInfo.available ? (
              <div className="info-content">
                <div className="status-indicator">
                  <span className="status-dot"></span>
                  <span className="status-text">Delivery Available</span>
                </div>
                <div className="delivery-details">
                  <p className="estimation">
                    Estimated delivery in <span>{deliveryInfo.estimatedDays} business days</span>
                  </p>
                  <p className="expected-date">
                    Expected by {deliveryInfo.expectedDate}
                  </p>
                  {/* {deliveryInfo.shippingCost > 0 && (
                    <p className="shipping-cost">
                      Shipping Cost: ₹{deliveryInfo.shippingCost.toFixed(2)}
                    </p>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="info-content">
                <div className="status-indicator">
                  <span className="status-dot"></span>
                  <span className="status-text">{deliveryInfo.message}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const ImageGallery = ({ images, selectedImage, setSelectedImage }) => {
  const handlePrevious = () => {
    setSelectedImage((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNext = () => {
    setSelectedImage((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="main-image-container" {...swipeHandlers}>
      {images && images.length > 1 && (
        <button 
          className="nav-button prev" 
          onClick={(e) => {
            e.stopPropagation();
            handlePrevious();
          }}
          aria-label="Previous image"
        >
          <FaChevronLeft />
        </button>
      )}
      
      {images && images.length > 0 && (
        <img 
          src={images[selectedImage]} 
          alt={`Product view ${selectedImage + 1}`}
          className="main-image"
        />
      )}
      
      {images && images.length > 1 && (
        <button 
          className="nav-button next" 
          onClick={(e) => {
            e.stopPropagation();
            handleNext();
          }}
          aria-label="Next image"
        >
          <FaChevronRight />
        </button>
      )}
      
      {images && images.length > 1 && (
        <div className="pagination-dots">
          {images.map((_, index) => (
            <button
              key={index}
              className={`dot ${selectedImage === index ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedImage(index);
              }}
              aria-label={`Go to image ${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ProductDetailsPage = () => {
  const { addToCart } = useCart();
  const { openCart } = useCartUI();
  const { id } = useParams();
  const { state } = useLocation();
  const [product, setProduct] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedFragrance, setSelectedFragrance] = useState(null);
  const [selectedVolume, setSelectedVolume] = useState(null);
  const [availableVolumes, setAvailableVolumes] = useState([]);
  const [availableFragrances, setAvailableFragrances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`products/${id}`);
        const productData = response.data;
        
        productData.rating = productData.rating || 4.5;
        productData.totalReviews = productData.totalReviews || 245;
        setProduct(productData);
  
        // Find the specific variation if variationId was passed
        if (state?.selectedVariationId) {
          console.log('Looking for variation:', state.selectedVariationId);
          console.log('Available variations:', productData.variations);
          
          const variation = productData.variations.find(
            v => v._id === state.selectedVariationId
          );
          
          console.log('Found variation:', variation);
          
          if (variation) {
            // Set the specific variation and its corresponding fragrance/volume
            setSelectedVariation(variation);
            // setSelectedFragrance(variation.fragrance);
            // setSelectedVolume(variation.volume);
          }
        }
  
        // Existing initialization code...
        if (productData.category === 'SURFACE CARE') {
          const fragrances = [...new Set(
            productData.variations
              .filter(v => v.fragrance)
              .map(v => v.fragrance)
          )].filter(Boolean);
          
          setAvailableFragrances(fragrances);
          
          // Only set default fragrance if no specific variation was selected
          if (fragrances.length > 0 && !state?.selectedVariationId) {
            setSelectedFragrance(fragrances[0]);
          }
        } else {
          const volumes = [...new Set(productData.variations.map(v => v.volume))];
          setAvailableVolumes(volumes);
          // Only set default volume if no specific variation was selected
          if (!state?.selectedVariationId) {
            setSelectedVolume(volumes[0]);
          }
        }
  
      } catch (err) {
        setError('Failed to fetch product details. Please try again later.');
        console.error('Error fetching product:', err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProduct();
  }, [id, state?.selectedVariationId]); 

  useEffect(() => {
    if (product && product.category === 'SURFACE CARE' && selectedFragrance) {
      const volumes = [...new Set(
        product.variations
          .filter(v => v.fragrance === selectedFragrance)
          .map(v => v.volume)
      )];
      setAvailableVolumes(volumes);
      setSelectedVolume(volumes[0]);
    }
  }, [selectedFragrance, product]);

  useEffect(() => {
    if (product) {
      let matchingVariation;
      
      if (product.category === 'SURFACE CARE') {
        matchingVariation = product.variations.find(v => 
          v.fragrance === selectedFragrance && 
          v.volume === selectedVolume
        );
      } else {
        matchingVariation = product.variations.find(v => 
          v.volume === selectedVolume
        );
      }
      
      if (matchingVariation) {
        setSelectedVariation(matchingVariation);
        setSelectedImage(0);
      }
    }
  }, [selectedFragrance, selectedVolume, product]);

  const handleFragranceChange = (fragrance) => {
    setSelectedFragrance(fragrance);
  };

  const handleVolumeChange = (volume) => {
    setSelectedVolume(volume);
  };

  const handleAddToCart = async () => {
    if (!selectedVariation.stock) {
      toast.error('Product is out of stock');
      return false;
    }
    
    const cartItem = {
      productId: product._id,
      variationId: selectedVariation._id,
      name: selectedVariation.name,
      price: Math.round(selectedVariation.discount > 0 
        ? selectedVariation.price * (1 - selectedVariation.discount / 100) 
        : selectedVariation.price),
      originalPrice: Math.round(selectedVariation.price),
      imageUrl: selectedVariation.imageUrls,
      volume: selectedVariation.volume,
      fragrance: selectedVariation.fragrance,
      quantity: quantity
    };
    
    try {
      addToCart(cartItem);
      toast.success(`${quantity} ${quantity > 1 ? 'items' : 'item'} added to cart`, {
        icon: '🛒'
      });
      return true;
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add to cart');
      return false;
    }
  };

  const handleBuyNow = async () => {
    if (!selectedVariation.stock) {
      toast.error('Product is out of stock');
      return;
    }

    setIsLoading(true);
    try {
      const success = await handleAddToCart();
      if (success) {
        openCart();
      }
    } catch (error) {
      console.error('Error in buy now flow:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  

  

  if (loading || !product || !selectedVariation) {
    return (
      <div className="product-details-page loading">
        <div className="loading-spinner"></div>
        <p>Loading product details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="product-details-page error">
        <div className="error-container">
          <h2>Error</h2>
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>Try Again</button>
        </div>
      </div>
    );
  }

  
  

  return (
    <div className="page-wrapper">
      <Toaster />
      <div className="product-details-page">
        <main className="main-content">
          <div className="product-details">
            <div className="product-images">
              <ImageGallery 
                images={selectedVariation?.imageUrls || []}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
              <div className="thumbnail-container">
                {selectedVariation?.imageUrls?.map((image, index) => (
                  <div
                    key={index}
                    className={`thumbnail ${selectedImage === index ? 'selected' : ''}`}
                    onClick={() => setSelectedImage(index)}
                  >
                    <img src={image} alt={`${selectedVariation.name} - Image ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>

            <div className="product-info">
              <h1>{selectedVariation.name}</h1>
              <StarRating rating={product.rating} totalReviews={product.totalReviews} />
              
              <div className="price-container">
  <p className="sale-price">
    ₹{Math.round(selectedVariation.price * (1 - (selectedVariation.discount || 0) / 100))}
  </p>
  {selectedVariation.discount > 0 && (
    <>
      <p className="original-price">MRP: ₹{Math.round(selectedVariation.price)}</p>
      <p className="savings">({selectedVariation.discount}% off)</p>
    </>
  )}
  <p className="tax-info">(Incl. of all taxes)</p>
</div>


             

              {product.category === 'SURFACE CARE' && (
                <div className="options">
                  <div className="option">
                    <h3>Fragrance:</h3>
                    <div className="option-buttons">
                      {availableFragrances.map((fragrance) => (
                        <button
                          key={fragrance}
                          className={`option-button ${selectedFragrance === fragrance ? 'selected' : ''}`}
                          onClick={() => handleFragranceChange(fragrance)}
                        >
                          {fragrance}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {availableVolumes.length > 0 && (
                <div className="options">
                  <div className="option">
                    <h3>Volume:</h3>
                    <div className="option-buttons">
                      {availableVolumes.map((volume) => (
                        <button
                          key={volume}
                          className={`option-button ${selectedVolume === volume ? 'selected' : ''}`}
                          onClick={() => handleVolumeChange(volume)}
                        >
                          {volume}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="quantity">
                <h3>Quantity:</h3>
                <div className="quantity-selector">
                  <button 
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    disabled={quantity <= 1}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    min="1"
                    max={selectedVariation.stock}
                    value={quantity}
                    onChange={(e) => {
                      const val = parseInt(e.target.value);
                      if (!isNaN(val)) {
                        setQuantity(Math.min(Math.max(1, val), selectedVariation.stock));
                      }
                    }}
                  />
                  <button 
                    onClick={() => setQuantity(Math.min(quantity + 1, selectedVariation.stock))}
                    disabled={quantity >= selectedVariation.stock}
                  >
                    +
                  </button>
                </div>
              </div>

              {selectedVariation.stock < 10 && (
                <p className="stock-warning">
                  <span className="stock-icon">⚠️</span>
                  Only {selectedVariation.stock} units left in stock!
                </p>
              )}

              <Bought/>

<div className="action-buttons">
          <button 
            className="add-to-cart"
            onClick={handleAddToCart}
            disabled={!selectedVariation.stock || isLoading}
          >
            {!selectedVariation.stock ? 'Out of Stock' : 'Add to Cart'}
          </button>
          <button 
            className="buy-now"
            onClick={handleBuyNow}
            disabled={!selectedVariation.stock || isLoading}
          >
            {isLoading ? 'Processing...' : (!selectedVariation.stock ? 'Out of Stock' : 'Buy Now')}
          </button>
        </div>

              <ServiceBenefits />
              <DeliveryEstimation product={selectedVariation} />

              {/* <div className="product-features">
                <h3>Key Features</h3>
                <ul className="features-list">
                  <li>Category: {product.category}</li>
                  <li>Volume: {selectedVariation.volume}</li>
                  {selectedVariation.fragrance && (
                    <li>Fragrance: {selectedVariation.fragrance}</li>
                  )}
                  <li>Availability: {selectedVariation.stock > 0 ? 'In Stock' : 'Out of Stock'}</li>
                </ul>
              </div> */}

<div className="product-about">
  <h3>About this item</h3>
  <div className="product-description">
    <ul className="description-bullets">
      {selectedVariation.description.split('~').filter(point => point.trim()).map((point, index) => (
        <li key={index}>{point.trim()}</li>
      ))}
    </ul>
  </div>
</div>
            </div>
          </div>

          {selectedVariation.descriptionImages && selectedVariation.descriptionImages.length > 0 && (
            <div className="description-section">
              <h3 className="description-title">Product Details</h3>
              <div className="description-images">
                {selectedVariation.descriptionImages.map((image, index) => (
                  <div key={index} className="description-image-wrapper">
                    <img 
                      src={image} 
                      alt={`Product description ${index + 1}`}
                      className="description-image"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

<div className="related-products">
  <div className="related-products-grid">
  <h2 style={{ textAlign: 'center', margin: '30px auto 0px auto' }}>People Also Like These Products</h2>

      <BestSellers />
      <ReviewSlider productId="6744819219aecc37a125eb29" />

  </div>
</div>
        </main>
        

        <Footer />
      </div>
    </div>
  );
};

export default ProductDetailsPage;
import React, { useState, useEffect } from 'react';
import { HeartOutlined, EyeOutlined, ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import '../../styles/BestSeller.scss';
import instance from '../../helpers/axiosconfig';

const ComboPackShowcase = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/products');
      const expandedProducts = response.data.flatMap(product => 
        product.variations.map(variation => ({
          _id: product._id,
          category: product.category,
          baseDescription: product.baseDescription,
          variations: [variation]
        }))
      );
      const comboPacks = expandedProducts.filter(product => 
        product.variations[0].volume.toLowerCase().includes('pack of')
      );
      setProducts(comboPacks);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleQuickView = (productId, variationId) => {
    const id = productId.$oid || productId;
    navigate(`/product/${id}`, {
      state: { 
        selectedVariationId: variationId.$oid || variationId
      }
    });
  };

  const renderStockBadge = (stock) => {
    if (stock > 10) {
      return (
        <div className="bestseller__badge bestseller__badge--in-stock">
          <CheckCircleFilled className="bestseller__badge-icon" />
        </div>
      );
    } else if (stock > 0) {
      return (
        <div className="bestseller__badge bestseller__badge--low-stock">
          <ExclamationCircleFilled className="bestseller__badge-icon" />
          <span>Only {stock} left</span>
        </div>
      );
    }
    return (
      <div className="bestseller__badge bestseller__badge--out-stock">
        <ExclamationCircleFilled className="bestseller__badge-icon" />
        <span>Out of Stock</span>
      </div>
    );
  };

  // Add price formatting utility function
  const formatPrice = (price) => {
    return Math.round(price).toLocaleString('en-IN');
  };

  if (loading) return <div className="bestseller__loading">Loading products...</div>;
  if (error) return <div className="bestseller__error">{error}</div>;

  return (
    <div className="bestseller-showcase">
      <div className="bestseller__grid">
        {products.map((product) => {
          const variation = product.variations[0];
          // Calculate rounded prices
          const originalPrice = Math.round(variation.price);
          const discountedPrice = Math.round(variation.price * (1 - variation.discount / 100));
          
          return (
            <div 
              key={variation._id.$oid} 
              className="bestseller__card"
              onClick={() => handleQuickView(product._id, variation._id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="bestseller__header">
                {renderStockBadge(variation.stock)}
                {variation.discount > 0 && (
                  <div className="bestseller__discount">
                    <span>{variation.discount}% OFF</span>
                  </div>
                )}
              </div>

              <div className="bestseller__image-container">
                <img 
                  src={variation.imageUrls[0]}
                  alt={variation.name}
                  loading="lazy"
                />
                <button 
                  className="bestseller__wishlist"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <HeartOutlined />
                </button>
              </div>

              <div className="bestseller__content">
                <div className="bestseller__category">
                  <span>{product.category}</span>
                </div>
                
                <h3 className="bestseller__name">{variation.name}</h3>
                
                <div className="bestseller__details">
                  <div className="bestseller__volume">
                    <span className="bestseller__label" style={{ fontFamily: "'Libre Bodoni', serif" }}>
                      Size
                    </span>
                    <span className="bestseller__value">{variation.volume}</span>
                  </div>
                </div>

                <div className="bestseller__price-block">
                  <div className="bestseller__price-info">
                    <div className="bestseller__current-price">
                      <span className="bestseller__currency">₹</span>
                      <span className="bestseller__amount">
                        {variation.discount > 0 ? formatPrice(discountedPrice) : formatPrice(originalPrice)}
                      </span>
                    </div>
                    {variation.discount > 0 && (
                      <div className="bestseller__original-price">
                        <span className="bestseller__mrp" style={{ fontFamily: "'Libre Bodoni', serif" }}>
                          MRP ₹{formatPrice(originalPrice)}
                        </span>
                        <div className="bestseller__tax-info" style={{ fontFamily: "'Libre Bodoni', serif" }}>
                          (incl. of all taxes)
                        </div>
                      </div>
                    )}
                  </div>
                  {variation.stock > 0 && (
                    <div className="bestseller__shipping">
                      <span style={{ fontFamily: "'Libre Bodoni', serif" }}>
                        Free Delivery
                      </span>
                    </div>
                  )}
                </div>

                <div className="bestseller__actions">
                  <button 
                    className="bestseller__button bestseller__button--primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleQuickView(product._id, variation._id);
                    }}
                  >
                    <EyeOutlined />
                    <span style={{ fontFamily: "'Libre Bodoni', serif" }}>
                      Quick View
                    </span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ComboPackShowcase;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  // Load cart from localStorage on mount
  useEffect(() => {
    try {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      const storedCoupon = JSON.parse(localStorage.getItem('selectedCoupon'));
      
      if (storedCart.length > 0) {
        setCart(storedCart);
        window.dispatchEvent(new CustomEvent('cartUpdate', {
          detail: { cart: storedCart }
        }));
      }
      
      if (storedCoupon) {
        setSelectedCoupon(storedCoupon);
      }
    } catch (error) {
      console.error('Error loading cart from storage:', error);
      setCart([]);
      setSelectedCoupon(null);
    }
  }, []);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(cart));
      localStorage.setItem('selectedCoupon', JSON.stringify(selectedCoupon));
      window.dispatchEvent(new CustomEvent('cartUpdate', {
        detail: { cart }
      }));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  }, [cart, selectedCoupon]);

  // Calculate cart total
  const getCartTotal = () => {
    return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  // Validate coupon against cart total
  const validateCoupon = (coupon, total) => {
    if (!coupon) return true;
    return total >= coupon.minAmount;
  };

  // Check and remove invalid coupon
  useEffect(() => {
    if (selectedCoupon) {
      const cartTotal = getCartTotal();
      const isValidCoupon = validateCoupon(selectedCoupon, cartTotal);

      if (!isValidCoupon) {
        toast.info(`Coupon ${selectedCoupon.code} removed as cart total is below minimum required amount of ₹${selectedCoupon.minAmount}`);
        setSelectedCoupon(null);
        localStorage.removeItem('selectedCoupon');
      }
    }
  }, [cart, selectedCoupon]);

  const addToCart = (item) => {
    setCart(prevCart => {
      const existingItemIndex = prevCart.findIndex(
        cartItem => 
          cartItem.productId === item.productId && 
          cartItem.variationId === item.variationId
      );

      if (existingItemIndex > -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: updatedCart[existingItemIndex].quantity + (item.quantity || 1)
        };
        toast.success(`${item.name} updated in cart`);
        return updatedCart;
      }

      const newItem = { ...item, quantity: item.quantity || 1 };
      toast.success(`${item.name} added to cart`);
      return [...prevCart, newItem];
    });
  };

  const removeFromCart = (productId, variationId) => {
    setCart(prevCart => {
      const itemToRemove = prevCart.find(item => 
        item.productId === productId && item.variationId === variationId
      );
      const updatedCart = prevCart.filter(item => 
        !(item.productId === productId && item.variationId === variationId)
      );
      
      if (itemToRemove) {
        toast.info(`${itemToRemove.name} removed from cart`);
      }
      return updatedCart;
    });
  };

  const updateQuantity = (productId, variationId, change) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map(item => {
        if (item.productId === productId && item.variationId === variationId) {
          const newQuantity = Math.max(1, item.quantity + change);
          const updatedItem = { ...item, quantity: newQuantity };
          
          if (newQuantity === 1 && change < 0) {
            toast.info(`Minimum quantity reached for ${item.name}`);
          } else if (change > 0) {
            toast.success(`Added one more ${item.name}`);
          } else if (change < 0) {
            toast.info(`Removed one ${item.name}`);
          }
          
          return updatedItem;
        }
        return item;
      }).filter(item => item.quantity > 0);

      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart([]);
    setSelectedCoupon(null);
    localStorage.removeItem('cart');
    localStorage.removeItem('selectedCoupon');
    localStorage.removeItem('checkoutData');
    toast.info('Cart cleared');
  };

  const applyCoupon = (coupon) => {
    const cartTotal = getCartTotal();
    if (validateCoupon(coupon, cartTotal)) {
      setSelectedCoupon(coupon);
      toast.success(`Coupon ${coupon.code} applied successfully!`);
    } else {
      toast.error(`Cart total must be at least ₹${coupon.minAmount} to use this coupon`);
    }
  };

  const removeCoupon = () => {
    setSelectedCoupon(null);
    localStorage.removeItem('selectedCoupon');
    toast.info('Coupon removed');
  };

  return (
    <CartContext.Provider value={{
      cart,
      selectedCoupon,
      addToCart,
      removeFromCart,
      updateQuantity,
      clearCart,
      applyCoupon,
      removeCoupon,
      getCartTotal
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
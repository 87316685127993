import React, { createContext, useContext, useState } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes, 
  Navigate, 
  useNavigate, 
  useLocation 
} from 'react-router-dom';

import HomePage from '../src/components/HomePage';
import ProductPage from './components/ProductPage';
import ProductDetailsPage from './components/ProductPageComponents/ProductDetails';
import CartPage from './components/CartPages/CartPage';
import 'antd/dist/reset.css';
import AnimatedBanner from '../src/components/HomePageComponents/AnimatedBanner';
import Navbar from './components/HomePageComponents/Navbar';
import OrdersList from './components/order/orders';
import Invoice from './components/order/Invoice';
import OrderTracking from './components/order/orderTracking';
import AddressCheckout from './components/order/AddressCheckout';
import PaymentCheckout from './components/order/PaymentCheckout';
import OrderSuccess from './components/order/OrderSuccess';
import LoginModal from './components/Signin_up/Signin';
import TermsAndConditions from './components/TermsCondition/Terms&Condition'; 
import Privacy from './components/TermsCondition/Privacy'; 
import About from './components/TermsCondition/about';
import ContactUs from './components/TermsCondition/ContactUs';
import { AuthProvider, useAuth } from './components/Signin_up/AuthContext';
import { CartProvider } from './utils/CartContext';
import { CartUIProvider } from './utils/CartUIContext';
import toast, { Toaster } from 'react-hot-toast';


// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, openAuthModal } = useAuth();
  
  React.useEffect(() => {
    if (!isAuthenticated) {
      openAuthModal(location.pathname);
    }
  }, [isAuthenticated, location, openAuthModal]);

  return isAuthenticated ? children : null;
};

// NotFound Component
const NotFound = () => {
  const navigate = useNavigate();
  
  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <button onClick={() => navigate('/')}>Go Home</button>
    </div>
  );
};

// Toast configuration
const toastConfig = {
  position: "top-center",  // Changed to top-center
  autoClose: 1000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: true,
  pauseOnHover: false,
  theme: "colored",
  toastStyle: {
    background: '#066469',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(6, 100, 105, 0.3)',
    transform: 'translateY(-100%)',  // For slide from top animation
  },
  progressStyle: {
    background: 'rgba(255, 255, 255, 0.4)'
  },
  closeButton: false,
  icon: true,
  limit: 3,
  // transition: Slide,
  swipeProgressDuration: 300,
  swipeProgress: true, 
  swipeClose: true,
  swipeDirection: 'up'  // Added for upward swipe
 };

 function App() {
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [isCartOpen, setIsCartOpen] = useState(false);

  return (
    <Router>
      <CartProvider>
        <Toaster />
        <CartUIProvider>
          <AuthProvider>
            <AppContent 
              cart={cart} 
              setCart={setCart} 
              isCartOpen={isCartOpen} 
              setIsCartOpen={setIsCartOpen} 
            />
          </AuthProvider>
        </CartUIProvider>
      </CartProvider>
    </Router>
  );
}

function AppContent({ cart, setCart, isCartOpen, setIsCartOpen }) {
  const location = useLocation();
  
  const addToCart = (cartItem) => {
    const updatedCart = [...cart];
    const existingItem = updatedCart.find(item => 
      item.productId === cartItem.productId && 
      item.variationId === cartItem.variationId
    );
    
    if (existingItem) {
      existingItem.quantity += cartItem.quantity || 1;
    } else {
      updatedCart.push(cartItem);
      toast.success(`${cartItem.name} added to cart`, {
        icon: '🛒'
      });
    }
    
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    window.dispatchEvent(new CustomEvent('cartUpdate', { detail: { cart: updatedCart } }));
  };

  return (
    <div className="App">
      {!location.pathname.includes('/checkout') && <Navbar />}
      
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<HomePage />} />
        <Route 
          path="/products" 
          element={
            <ProductPage 
              cart={cart}
              setCart={setCart}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              onAddToCart={addToCart}
              onOpenCart={() => setIsCartOpen(true)}
            />
          } 
        />
        <Route 
          path="/product/:id" 
          element={
            <ProductDetailsPage 
              onAddToCart={addToCart}
              onOpenCart={() => setIsCartOpen(true)}
            />
          } 
        />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />

        {/* Protected Routes */}
  <Route path="/orders">
    <Route
      index
      element={
        <ProtectedRoute>
          <OrdersList />
        </ProtectedRoute>
      }
    />
    <Route
      path="tracking/:orderId"
      element={
        <ProtectedRoute>
          <OrderTracking />
        </ProtectedRoute>
      }
    />
  </Route>

  {/* Add Invoice Route at root level */}
  <Route
    path="/orders/invoice/:orderId"
    element={
      <ProtectedRoute>
        <Invoice />
      </ProtectedRoute>
    }
  />

  <Route path="/checkout">
    <Route
      path="address"
      element={
        <ProtectedRoute>
          <AddressCheckout />
        </ProtectedRoute>
      }
    />
    <Route
      path="payment"
      element={
        <ProtectedRoute>
          <PaymentCheckout />
        </ProtectedRoute>
      }
    />
    <Route
      path="order-success"
      element={
        <ProtectedRoute>
          <OrderSuccess />
        </ProtectedRoute>
      }
    />
    <Route
      index
      element={<Navigate to="/checkout/address" replace />}
    />
  </Route>

  <Route path="*" element={<NotFound />} />
</Routes>
      
      {/* Global CartSidebar */}
      <CartPage 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cart={cart}
        setCart={setCart}
      />

      {/* Global Auth Modal */}
      <LoginModal />
    </div>
  );
}

export default App;